.con[data-v-7af8d576] {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.con .item[data-v-7af8d576] {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 25%;
    height: auto;
}
.con .item .item-title[data-v-7af8d576] {
      width: 100%;
      text-align: center;
}
.con .item .img-con[data-v-7af8d576] {
      cursor: pointer;
      width: 60px;
      height: 60px;
      margin: auto;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 8px;
      background-color: rgba(238, 238, 238, 0.509);
      border-radius: 4px;
      margin-bottom: 4px;
}
.con .item .img-con img[data-v-7af8d576] {
        width: 100%;
        height: 100%;
}
